import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { AlertController, ToastController } from '@ionic/angular';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  firebaseUrl = 'https://us-central1-doubt-s.cloudfunctions.net/';
  phone_no = '';
  constructor(
    public toastController: ToastController,
    public http: HttpClient,
    public alertCtrl : AlertController
  ) { }


  create_NewRequest(data) {
    return this.http.post(this.firebaseUrl + 'createNewRequest', data, {}).pipe(
      map((response: any) => response), catchError(this.errorHandler));
  }

  

  
  create_NewRating(data) {
    return this.http.post(this.firebaseUrl + 'createNewRating', data, {}).pipe(
      map((response: any) => response), catchError(this.errorHandler));
  }


  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }


  getQuestionsById(data) {
    return this.http.post(this.firebaseUrl + 'getQuestionsByQid', data, {}).pipe(
      map((response: any) => response), catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error || 'server error.');
  }


  // formatDate(date:Date): string{
  //   const day = date.getDate();
  //   const month = date.getMonth()+1;
  //   const year = date.getFullYear();
  //   return `${year}-${month}-${day}`;
  // }

  async showPrompt() {
    let prompt = await this.alertCtrl.create({
      message: "Enter a phone number",
      backdropDismiss:false,
      inputs: [
        {
          name: 'phone',
          placeholder: '+913453453345',
        }
      ],
      buttons: [
        {
          text: 'Save',
          handler: data => {
            if(!data.phone){
return false;
            }
            console.log(data)
            if(data.phone){
              this.phone_no = data.phone;
            }
            console.log('Saved clicked');
           
          }
        }
      ]
    });
    await prompt.present();
return prompt;
  }


}
